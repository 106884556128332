import Payments from "./Payments";

export default class PaypalPayment extends Payments {
  get basket() {
    const basket = [];

    this.cart?.Products?.map((item) => {
      const quantity = item?.Quantity;
      let promotionalPrice = item?.Price?.PromotionalPrice;
      let regularPrice = item?.Price?.RegularPrice;
      let price = regularPrice;

      if (promotionalPrice) {
        price = promotionalPrice;
      }

      const newItem = {
        name: item?.Description?.Title,
        description: `Ref :${item?.Reference}`,
        unit_amount: {
          currency_code: this.cart?.Currency?.IsoCode,
          value: price.PriceTaxIncl,
        },
        quantity: quantity,
      };

      basket.push(newItem);
    });

    return basket;
  }
}
